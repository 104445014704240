import { useMutation, useQueryClient } from "@tanstack/react-query"
import styled from "styled-components"
import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/navigation"
import { OptionGroup } from "./OptionGroup"
import { OptionGroupSelection } from "./interface"
import apiClient from "@api/client"
import { formatCurrency } from "@helpers/formatCurrency"
import {
  Loading,
  Divider,
  InputQuantity,
  Button,
  Text,
  Spacer,
  Row,
  InputCheckbox,
  Column,
  InputTextArea,
  Modal,
  Icon,
  IconKey,
} from "@openui"
import { useLoyaltyProgram } from "hooks/useLoyaltyProgram"
import { useMenuItem } from "hooks/useMenuItem"
import { useActiveStore } from "hooks/useActiveStore"
import { QUERY_KEYS } from "@api/constants"
import { IdentityContext } from "providers/IdentityProvider"
import { OrderItem } from "@models/orderItem"
import { MenuItem } from "@models/menuItem"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { MenuItemOptionGroup } from "@models/menuItemOptionGroup"
import { ModalController } from "@openui"

const getDefaultSelectionsForOptionGroup = (
  optionGroup: MenuItemOptionGroup,
): OptionGroupSelection => {
  const selections: OptionGroupSelection = {
    optionGroupId: optionGroup.id,
    options: {},
  }
  for (const option of optionGroup.options) {
    // we can't guarantee nested options have defaults so skip if an option has nesting
    if (option.isDefault && option.nestedOptionGroups.length == 0) {
      selections.options[option.id] = {
        optionId: option.id,
        quantity: 1,
        nestedOptionGroupSelection: {},
      }
      for (const nestedOptionGroup of option.nestedOptionGroups) {
        selections.options[option.id].nestedOptionGroupSelection[
          nestedOptionGroup.id
        ] = getDefaultSelectionsForOptionGroup(nestedOptionGroup)
      }
    }
  }
  return selections
}

const constructDefaultSelections = (
  menuItem: MenuItem,
): Record<number, OptionGroupSelection> => {
  const selectedOptionGroups: Record<number, OptionGroupSelection> = {}
  for (const optionGroup of menuItem.optionGroups) {
    selectedOptionGroups[optionGroup.id] =
      getDefaultSelectionsForOptionGroup(optionGroup)
  }
  return selectedOptionGroups
}

const constructSelectionFromOrderItem = (
  menuItem: MenuItem,
  orderItem: OrderItem,
): Record<number, OptionGroupSelection> => {
  // Our selections to be constructed.
  const selectedOptionGroups: Record<number, OptionGroupSelection> = {}
  // Order item options without a parent ID are root level.
  // First we get these, then we iterate over them and construct
  // their nested selections.
  const rootSelections = orderItem.orderItemOptions.filter(
    (oio) => oio.parentOrderItemOptionId === null,
  )
  for (const orderItemOption of rootSelections) {
    // To construct the selection, we need the parent option group ID.
    const parentOptionGroup = menuItem.optionGroups.find(
      (og) =>
        og.options.findIndex(
          (o) => o.id === orderItemOption.menuItemOptionId,
        ) !== -1,
    )
    if (parentOptionGroup) {
      // Once we've found the option group, we create the root-level menu item option selection.
      // This is different from the order item option selection.
      // We must be cognizant of the fact that the group might already exist and preserve
      // previously assigned selections.
      selectedOptionGroups[parentOptionGroup.id] = {
        optionGroupId: parentOptionGroup.id,
        options: {
          ...(selectedOptionGroups[parentOptionGroup.id]?.options ?? {}),
          [orderItemOption.menuItemOptionId]: {
            optionId: orderItemOption.menuItemOptionId,
            quantity: orderItemOption.quantity,
            nestedOptionGroupSelection: {},
          },
        },
      }
      // Search the list of order item options for any nested selections of the given order item option.
      const nestedOrderItemOptions = orderItem.orderItemOptions.filter(
        (oio) => oio.parentOrderItemOptionId === orderItemOption.id,
      )
      // From that list of nested order item options, find the corresponding menu item option.
      for (const nestedOrderItemOption of nestedOrderItemOptions) {
        // Parent option group...
        const optionGroup = menuItem.optionGroups.find(
          (og) => og.id === parentOptionGroup.id,
        )
        if (!optionGroup) continue
        // Parent option within that group...
        optionGroup.options.forEach((o) => {
          // Find the nested option group that contains the selection.
          o.nestedOptionGroups.forEach((nog) => {
            // Find parent order item option for o. If parent menu item ID doesn't match, bail.
            const parent = orderItem.orderItemOptions.find(
              (oio) => oio.id === nestedOrderItemOption.parentOrderItemOptionId,
            )
            if (parent?.menuItemOptionId !== o.id) return
            const nestedOption = nog.options.find(
              (no) => no.id === nestedOrderItemOption.menuItemOptionId,
            )
            // If we've found a nested option that matches, assign it as a child
            // of the selected option group we just created/updated.
            // Again, preserve preexisting selections if that option already has values assigned.
            if (!nestedOption) return
            selectedOptionGroups[parentOptionGroup.id].options[o.id] = {
              optionId: o.id,
              quantity:
                selectedOptionGroups[parentOptionGroup.id].options[o.id]
                  .quantity,
              nestedOptionGroupSelection: {
                ...(selectedOptionGroups[parentOptionGroup.id]?.options[o.id]
                  ?.nestedOptionGroupSelection ?? {}),
                [nog.id]: {
                  optionGroupId: nog.id,
                  options: {
                    ...(selectedOptionGroups[parentOptionGroup.id]?.options[
                      o.id
                    ]?.nestedOptionGroupSelection[nog.id]?.options ?? {}),
                    [nestedOption.id]: {
                      optionId: nestedOption.id,
                      quantity: nestedOrderItemOption.quantity,
                      // We don't support indefinite layers of nested options yet.
                      nestedOptionGroupSelection: {},
                    },
                  },
                },
              },
            }
          })
        })
      }
    }
  }
  return selectedOptionGroups
}

interface OptionParam {
  menuItemOptionId: number
  quantity: number
  nestedOptions: Array<OptionParam>
}

const mapOptionGroupSelectionToOptionParams = (
  input: OptionGroupSelection,
): Array<OptionParam> =>
  Object.values(input.options).map((o) => ({
    menuItemOptionId: o.optionId,
    quantity: o.quantity,
    nestedOptions: Object.values(o.nestedOptionGroupSelection).flatMap(
      mapOptionGroupSelectionToOptionParams,
    ),
  }))

interface Props {
  controller: ModalController
  storeMenuId: number
  menuItemId: number
  orderItem?: OrderItem
  onAddItemToCart?: () => void
}

export const MenuItemDetailModal = ({
  controller,
  storeMenuId,
  menuItemId,
  orderItem,
  onAddItemToCart,
}: Props) => {
  const router = useRouter()
  const identity = useContext(IdentityContext)
  const merchantConfig = useContext(MerchantConfigContext)
  const queryClient = useQueryClient()
  const { activeStore } = useActiveStore()
  const loyaltyProgram = useLoyaltyProgram()
  const [validate, setValidate] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [usePoints, setUsePoints] = useState(false)
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [selections, setSelections] = useState<
    Record<number, OptionGroupSelection>
  >({})
  useEffect(() => {
    if (controller.isOpen && !orderItem) {
      setQuantity(1)
      setSpecialInstructions("")
    }
  }, [controller.isOpen])
  const menuItemQuery = useMenuItem(storeMenuId, menuItemId)
  const addToCart = useMutation({
    mutationFn: async ({
      quantity,
      specialInstructions,
      optionParams,
      useRewardPoints,
    }: {
      quantity: number
      specialInstructions: string
      optionParams: Array<OptionParam>
      useRewardPoints: boolean
    }) => {
      if (!orderItem) {
        const res = await apiClient.post(
          `stores/${activeStore?.id}/cart/add-item/`,
          {
            menuItemId,
            storeMenuId,
            quantity,
            specialInstructions,
            menuItemOptionParams: optionParams,
            isReward: useRewardPoints,
          },
        )
        if (res?.statusCode !== 200)
          throw Error(
            (res?.body as unknown as { message: string }).message ?? "",
          )
      } else {
        const res = await apiClient.put(
          `stores/${activeStore?.id}/cart/edit-item/`,
          {
            orderItemId: orderItem.id,
            storeMenuId: storeMenuId,
            quantity: quantity,
            specialInstructions: specialInstructions,
            orderItemOptionParams: optionParams,
            isReward: useRewardPoints,
          },
        )
        if (res?.statusCode !== 200)
          throw Error(
            (res?.body as unknown as { message: string }).message ?? "",
          )
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CART] })
      identity.refresh()
      onAddItemToCart?.()
    },
    onError: () => {
      // TODO: Show toast
    },
  })

  useEffect(() => {
    if (menuItemQuery.data && orderItem) {
      setUsePoints(Boolean(orderItem.isReward))
      setQuantity(orderItem.quantity)
      setSpecialInstructions(orderItem.specialInstructions ?? "")
      setSelections(
        constructSelectionFromOrderItem(menuItemQuery.data, orderItem),
      )
    } else if (menuItemQuery.data && !orderItem) {
      setSelections(constructDefaultSelections(menuItemQuery.data))
    }
  }, [orderItem, menuItemQuery.data])

  const menuItem = menuItemQuery.data

  const getOptionGroupSelectionsPrice = (
    optionGroupSelection: OptionGroupSelection,
    optionGroup: MenuItemOptionGroup,
  ): number => {
    if (!menuItem) return 0
    let total = 0
    for (const optionKeyString of Object.keys(optionGroupSelection.options)) {
      let optionTotal = 0
      const optionKey = Number.parseInt(optionKeyString)
      const option = optionGroup.options.find((o) => o.id === optionKey)
      if (!option) continue
      const selection = optionGroupSelection.options[optionKey]
      optionTotal += option.priceCents
      for (const nestedOptionGroupKeyString of Object.keys(
        selection.nestedOptionGroupSelection,
      )) {
        const asInt = Number.parseInt(nestedOptionGroupKeyString)
        const nestedOptionGroup = option.nestedOptionGroups.find(
          (og) => og.id === asInt,
        )
        if (!nestedOptionGroup) continue
        optionTotal += getOptionGroupSelectionsPrice(
          optionGroupSelection.options[optionKey].nestedOptionGroupSelection[
            asInt
          ],
          nestedOptionGroup,
        )
      }
      total += optionTotal * selection.quantity
    }
    return total
  }

  const getTotalPrice = () => {
    if (!menuItem) return 0
    let total = menuItem.priceCents
    for (const optionGroupKeyString of Object.keys(selections)) {
      const asInt = Number.parseInt(optionGroupKeyString)
      const optionGroup = menuItem.optionGroups.find(
        (og) => og.id === selections[asInt].optionGroupId,
      )
      if (!optionGroup) continue
      total += getOptionGroupSelectionsPrice(selections[asInt], optionGroup)
    }
    return total * quantity
  }

  const getItemRewardPointCost = () => {
    const totalPrice = getTotalPrice()
    const dollarsPerPoint = loyaltyProgram.data?.dollarsPerPoint
    if (!dollarsPerPoint) return 0
    return Math.ceil(totalPrice / Number.parseFloat(dollarsPerPoint) / 100)
  }

  const formatAddToCartLabel = () => {
    if (!identity.identity) return "Log in"
    const totalPrice = getTotalPrice()
    let cta = ""
    const invalidCount = getInvalidOptionGroups().length
    if (invalidCount > 0) {
      cta = `${invalidCount} selection${invalidCount > 1 ? "s" : ""} required`
    } else {
      if (orderItem != null) {
        cta = "Update item"
      } else {
        cta = "Add to cart"
      }
    }
    if (!usePoints) {
      return `${cta} • ${formatCurrency(totalPrice)}`
    } else {
      return `${cta} • ${getItemRewardPointCost()} points`
    }
  }

  const getInvalidOptionGroups = () => {
    if (!menuItem) return []
    const invalid = []
    for (const optionGroup of menuItem.optionGroups) {
      const selectedOptionsInGroup = selections[optionGroup.id]?.options ?? {}
      // number of selections must be quantity aware now that we allow option quantities
      let optionGroupSelections = 0
      for (const option of Object.values(selectedOptionsInGroup)) {
        optionGroupSelections += option.quantity
      }
      if (
        optionGroup.minSelections > optionGroupSelections ||
        (optionGroup.maxSelections !== null &&
          optionGroup.maxSelections < optionGroupSelections)
      ) {
        invalid.push(optionGroup)
      }
    }
    return invalid
  }

  const handleSubmit = () => {
    if (!identity.identity) {
      router.push("login")
      return
    }

    setValidate(true)
    const invalidOptionGroups = getInvalidOptionGroups()
    if (invalidOptionGroups.length > 0) {
      // scroll to first invalid category
      document
        .getElementById(`option-group-${invalidOptionGroups[0].id}`)
        ?.scrollIntoView()
      return
    }
    addToCart.mutate({
      quantity,
      specialInstructions,
      optionParams: Object.values(selections).flatMap(
        mapOptionGroupSelectionToOptionParams,
      ),
      useRewardPoints: usePoints,
    })
  }

  const hasEnoughRewardPointsToPurchase =
    (identity.identity?.customer.loyaltyPoints ?? 0) +
      (orderItem?.rewardPointsUsed ?? 0) >=
    getItemRewardPointCost()

  useEffect(() => {
    if (!hasEnoughRewardPointsToPurchase && usePoints) {
      setUsePoints(false)
    }
  }, [hasEnoughRewardPointsToPurchase, usePoints, quantity])

  if (!controller.isOpen) {
    return null
  }

  if (menuItemQuery.isLoading)
    return (
      <Center>
        <Loading />
      </Center>
    )
  if (!menuItem) return <>failed to retrieve menu item</>

  return (
    <Modal
      controller={controller}
      padding="0px"
      footer={
        <Footer>
          <InputQuantity
            min={1}
            height="100%"
            quantity={quantity}
            onChange={setQuantity}
          />
          <Spacer size={2} $vertical />
          <Button
            width="100%"
            onClick={handleSubmit}
            isLoading={addToCart.isPending}
          >
            {formatAddToCartLabel()}
          </Button>
        </Footer>
      }
    >
      <Row justifyContent="flex-end">
        <Column padding="16px">
          <Icon iconKey={IconKey.Close} size={20} onClick={controller.close} />
        </Column>
      </Row>
      <Banner
        $imageSrc={
          menuItem.imageUrl ?? merchantConfig.config?.itemPlaceholderUrl
        }
      />
      <Section>
        <Text style="Heading/Bold">{menuItem.name}</Text>
        <Spacer size={1} />
        <Text style="Body/Medium">{formatCurrency(menuItem.priceCents)}</Text>
        <Spacer size={2} />
        <Text style="Body/Regular" color="Content/Secondary">
          {menuItem.description}
        </Text>
      </Section>
      <Divider />
      {menuItem.optionGroups.map((og) => (
        <OptionGroup
          key={og.id}
          optionGroup={og}
          selections={selections[og.id]}
          validate={validate}
          onUpdate={(optionGroupSelections) =>
            setSelections((prev) => ({
              ...prev,
              [og.id]: optionGroupSelections,
            }))
          }
        />
      ))}
      {!!menuItem.specialInstructionEnabled ||
      !!activeStore?.allowSpecialInstructions ? (
        <Section>
          <Text style="Label/Regular" color="Content/Secondary">
            Special instructions
          </Text>
          <Spacer size={1} />
          <InputTextArea
            value={specialInstructions}
            onChange={setSpecialInstructions}
          />
        </Section>
      ) : null}
      {identity.identity && (
        <>
          <Divider />
          <Section>
            <Text style="Body/Bold">Pay with points</Text>
            <Spacer size={2} />
            <LineItem
              onClick={() =>
                hasEnoughRewardPointsToPurchase && setUsePoints(!usePoints)
              }
              active={hasEnoughRewardPointsToPurchase}
            >
              <Row alignItems="center">
                <InputCheckbox value={usePoints} onChange={() => {}} />
                <Spacer size={2} $vertical />
                <Column>
                  <Text style="Body/Regular" color="Content/Secondary">
                    Pay with points
                  </Text>
                  <Text style="Label/Regular" color="Content/Secondary">
                    Current balance:{" "}
                    {(identity.identity?.customer.loyaltyPoints ?? 0) +
                      (orderItem?.rewardPointsUsed ?? 0)}
                  </Text>
                </Column>
              </Row>
              <Text style="Body/Regular" color="Content/Secondary">
                {getItemRewardPointCost()}
              </Text>
            </LineItem>
            <Spacer size={4} />
          </Section>
        </>
      )}
    </Modal>
  )
}

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Banner = styled.div<{ $imageSrc?: string }>`
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${({ $imageSrc }) => `background-image: url("${$imageSrc}");`};
`

const Section = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  display: flex;
  padding: 12px;
  height: 70px;
`

const LineItem = styled.div<{ active: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  ${({ active }) => (active ? "opacity: 1;" : "opacity: 0.6;")}
`
