import { useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import apiClient from "@api/client"
import { QUERY_KEYS } from "@api/constants"
import { LoyaltyProgram } from "@models/loyaltyProgram"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"

export const getLoyaltyProgramQuery = (merchantId: number) => {
  return {
    enabled: merchantId != null,
    queryKey: [QUERY_KEYS.GET_MERCHANT_LOYALTY_PROGRAM, merchantId],
    queryFn: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await apiClient.get<{ program: { [key: string]: any } }>(
        `merchants/${merchantId}/loyalty_program/`,
      )
      if (
        response?.statusCode !== 200 ||
        !response.body ||
        response.body.program === null
      ) {
        return null
      }
      return response.body.program
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: { [key: string]: any } | null) => {
      return data === null ? null : LoyaltyProgram.fromDynamic(data)
    },
  }
}

export const useLoyaltyProgram = () => {
  const merchantConfig = useContext(MerchantConfigContext)
  return useQuery(getLoyaltyProgramQuery(merchantConfig.config!.merchantId))
}
