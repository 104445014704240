export class LoyaltyProgram {
  constructor(
    public id: number,
    public merchantId: number,
    public pointsPerDollar: string,
    public pointName: string | null,
    public dollarsPerPoint: string | null
  ) {}

  static fromDynamic(data: { [key: string]: any }): LoyaltyProgram {
    return new LoyaltyProgram(
      data["id"],
      data["merchant_id"],
      data["points_per_dollar"],
      data["point_name"],
      data["dollars_per_point"]
    );
  }
}
