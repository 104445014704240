import apiClient from "@api/client"
import { MenuItem } from "@models/menuItem"
import { useQuery } from "@tanstack/react-query"

export const useMenuItem = (storeMenuId: number, menuItemId: number) => {
  const menuItemQuery = useQuery({
    queryKey: ["GET_MENU_ITEM", menuItemId],
    queryFn: async () => {
      const res = await apiClient.get(
        `menus/${storeMenuId}/items/${menuItemId}/`,
      )
      return res?.body ? MenuItem.fromDynamic(res.body) : undefined
    },
  })
  return menuItemQuery
}
