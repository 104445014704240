import styled, { useTheme } from "styled-components"
import { useState } from "react"
import { OptionGroupSelection } from "./interface"
import { NestedOptionGroups } from "./NestedOptionGroupsModal"
import { formatCurrency } from "@helpers/formatCurrency"
import { MenuItemOptionGroup } from "@models/menuItemOptionGroup"
import {
  Row,
  Text,
  InputCheckbox,
  Spacer,
  mapWithJoin,
  InputRadio,
  useModal,
  Column,
  Icon,
  IconKey,
  InputQuantity,
} from "@openui"
import { MenuItemOption } from "@models/menuItemOption"

interface Props {
  optionGroup: MenuItemOptionGroup
  selections: OptionGroupSelection | undefined
  validate: boolean
  onUpdate: (selections: OptionGroupSelection) => void
}

export const OptionGroup = ({
  optionGroup,
  selections = { optionGroupId: optionGroup.id, options: {} },
  validate,
  onUpdate,
}: Props) => {
  const theme = useTheme()
  const [viewingNestedOptionsOf, setViewingNestedOptionsOf] =
    useState<MenuItemOption>()
  const nestedOptionModalController = useModal()
  const viewNestedOptions = (option: MenuItemOption) => {
    const selectionCount = Object.keys(selections.options).reduce(
      (acc, add) => acc + selections.options[Number(add)].quantity,
      0,
    )
    if (
      optionGroup.maxSelections !== null &&
      optionGroup.maxSelections === selectionCount &&
      !selections.options[option.id] &&
      optionGroup.maxSelections > 1
    ) {
      return
    }
    setViewingNestedOptionsOf(option)
    nestedOptionModalController.open()
  }
  const submitNestedOptions = (
    nestedSelections: Record<number, OptionGroupSelection>,
  ) => {
    const newOptions =
      optionGroup.maxSelections === 1 ? {} : { ...selections.options }
    newOptions[viewingNestedOptionsOf!.id] = {
      optionId: viewingNestedOptionsOf!.id,
      quantity: newOptions[viewingNestedOptionsOf!.id]?.quantity ?? 1,
      nestedOptionGroupSelection: nestedSelections,
    }
    onUpdate({
      optionGroupId: optionGroup.id,
      options: newOptions,
    })
    nestedOptionModalController.close()
    setViewingNestedOptionsOf(undefined)
  }

  // Filter out options that are not available.
  const availableOptions = optionGroup.options.filter((o) => o?.isAvailable)
  // If there are no options available, return nothing.
  if (!availableOptions.length) return null
  const isRequired = optionGroup.minSelections > 0
  // If we allow duplicates of any option, "select up to X" doesn't make sense - we should hide the label entirely.
  const hasAllowsDuplicates =
    -1 !== optionGroup.options.findIndex((o) => o.allowsDuplicates)
  let quantityInstruction = ""
  if (hasAllowsDuplicates && optionGroup.maxSelections === null) {
    quantityInstruction = ""
  } else if (
    optionGroup.maxSelections !== null &&
    optionGroup.minSelections !== 0 &&
    optionGroup.minSelections !== optionGroup.maxSelections
  ) {
    quantityInstruction = `Select between ${optionGroup.minSelections} and ${optionGroup.maxSelections}`
  } else if (optionGroup.minSelections == 0) {
    quantityInstruction = `Select up to ${Math.min(optionGroup.maxSelections ?? availableOptions.length, availableOptions.length)}`
  } else if (optionGroup.minSelections == optionGroup.maxSelections) {
    quantityInstruction = `Select ${optionGroup.maxSelections}`
  } else {
    quantityInstruction = `Select at least ${optionGroup.minSelections}`
  }
  const selectionCount = Object.keys(selections.options).reduce(
    (acc, add) => acc + selections.options[Number(add)].quantity,
    0,
  )
  const hasRequiredQuantity = validate
    ? optionGroup.minSelections <= selectionCount &&
      (optionGroup.maxSelections !== null
        ? optionGroup.maxSelections >= selectionCount
        : true)
    : true

  const handleSelectOption = (o: MenuItemOption) => {
    const newOptions =
      optionGroup.maxSelections === 1 ? {} : { ...selections.options }
    const totalSelectionCount = Object.keys(newOptions).reduce(
      (acc, add) => acc + newOptions[Number(add)].quantity,
      0,
    )
    if (newOptions[o.id]) {
      delete newOptions[o.id]
    } else if (
      optionGroup.maxSelections === null ||
      totalSelectionCount < optionGroup.maxSelections
    ) {
      if (o.nestedOptionGroups.length > 0) {
        viewNestedOptions(o)
        return
      } else {
        newOptions[o.id] = {
          optionId: o.id,
          quantity: 1,
          nestedOptionGroupSelection: {},
        }
      }
    }
    // Allow deselecting in edge case where there's only one optional selection.
    if (
      optionGroup.maxSelections === 1 &&
      !optionGroup.minSelections &&
      selections.options[o.id] &&
      newOptions[o.id]
    ) {
      delete newOptions[o.id]
    }
    onUpdate({
      optionGroupId: optionGroup.id,
      options: newOptions,
    })
  }

  const handleSetOptionQuantity = (
    o: MenuItemOption,
    quantity: number,
    nestedOptionGroupSelection?: Record<number, OptionGroupSelection>,
  ) => {
    if (quantity < 0) return
    const newOptions = { ...selections.options }
    const totalSelectionCount = Object.keys(newOptions).reduce(
      (acc, add) => acc + newOptions[Number(add)].quantity,
      0,
    )
    const lastQuantity = newOptions[o.id]?.quantity ?? 0
    const delta = quantity - lastQuantity
    // If the new quantity would put us over the max selection count, exit.
    if (
      optionGroup.maxSelections !== null &&
      optionGroup.maxSelections < totalSelectionCount + delta
    )
      return
    // Otherwise, set the new quantity.
    if (o.nestedOptionGroups.length > 0 && quantity === 0) {
    } else {
      newOptions[o.id] = {
        optionId: o.id,
        quantity,
        nestedOptionGroupSelection: nestedOptionGroupSelection ?? {},
      }
    }
    // If the new quantity is 0, remove the selection.
    if (quantity === 0) {
      delete newOptions[o.id]
    }
    onUpdate({
      optionGroupId: optionGroup.id,
      options: newOptions,
    })
  }

  return (
    <Container id={`option-group-${optionGroup.id}`}>
      <Text style="Subheading/Bold">{optionGroup.name}</Text>
      <Spacer size={1} />
      <Row justifyContent="space-between">
        <Text style="Body/Regular" color="Content/Secondary">
          {quantityInstruction}
        </Text>
        <Text
          style="Body/Regular"
          color={hasRequiredQuantity ? "Content/Secondary" : "Content/Error"}
        >
          {isRequired ? "Required" : "Optional"}
        </Text>
      </Row>
      <Spacer size={4} />
      {mapWithJoin(
        availableOptions,
        (o: MenuItemOption) => {
          const nestedOptionGroupSelections = Object.values(
            selections.options[o.id]?.nestedOptionGroupSelection ?? {},
          )
          const nestedOptionSelections = nestedOptionGroupSelections.flatMap(
            (selectedOptionGroup) => {
              return Object.values(selectedOptionGroup.options).map(
                (selectedOption) => {
                  return (
                    (o.nestedOptionGroups
                      .find((og) => og.id === selectedOptionGroup.optionGroupId)
                      ?.options.find((o) => o.id === selectedOption.optionId)
                      ?.name ?? "") +
                    (selectedOption.quantity > 1
                      ? ` x${selectedOption.quantity}`
                      : "")
                  )
                },
              )
            },
          )
          let input = (
            <InputCheckbox
              value={Boolean(selections?.options[o.id])}
              onChange={() => {}}
            />
          )
          if (
            optionGroup.maxSelections === 1 &&
            optionGroup.minSelections === 1
          ) {
            input = (
              <InputRadio
                value={Boolean(selections?.options[o.id])}
                onChange={() => {}}
              />
            )
          }
          if (o.allowsDuplicates) {
            const shouldShowQuantity =
              o.nestedOptionGroups.length > 0
                ? selections.options[o.id]?.quantity > 0
                : true
            if (shouldShowQuantity) {
              input = (
                <InputQuantity
                  onChange={(quantity) =>
                    handleSetOptionQuantity(
                      o,
                      quantity,
                      selections.options[o.id]?.nestedOptionGroupSelection,
                    )
                  }
                  quantity={selections.options[o.id]?.quantity ?? 0}
                />
              )
            } else {
              input = <></>
            }
          }
          return (
            <LineItem
              onClick={() => {
                if (o.allowsDuplicates && o.nestedOptionGroups.length > 0) {
                  viewNestedOptions(o)
                } else if (!o.allowsDuplicates) {
                  handleSelectOption(o)
                }
              }}
            >
              <Row alignItems="center">
                {input}
                <Spacer size={2} $vertical />
                <Column>
                  <Text style="Body/Regular" color="Content/Primary">
                    {o.name}
                  </Text>
                  {nestedOptionSelections.length > 0 && (
                    <Text style="Label/Regular" color="Content/Secondary">
                      {nestedOptionSelections.join(", ")}
                    </Text>
                  )}
                </Column>
              </Row>
              <Row width="fit-content" alignItems="center">
                {o.priceCents !== 0 ? (
                  <Text style="Body/Regular" color="Content/Secondary">
                    {formatCurrency(o.priceCents)}
                  </Text>
                ) : null}
                {o.nestedOptionGroups.length > 0 && (
                  <Icon
                    iconKey={IconKey.ChevronRight}
                    color={theme.colors["Content/Secondary"]}
                  />
                )}
              </Row>
            </LineItem>
          )
        },
        () => (
          <Spacer size={4} />
        ),
      )}
      {viewingNestedOptionsOf && (
        <NestedOptionGroups
          controller={nestedOptionModalController}
          option={viewingNestedOptionsOf}
          onSubmit={submitNestedOptions}
          initialSelections={
            selections.options[viewingNestedOptionsOf.id]
              ?.nestedOptionGroupSelection ?? {}
          }
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
`

const LineItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
`
